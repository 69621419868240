import { SharedService } from './../shared-service/shared.service';
import { Message } from 'src/app/models/message';
import { LoaderService } from './../loader/loader.service';
import { OutGoingMessage, SocketService } from './../socket/socket.service';
import { Iframe } from './../../models/Iframe';
import {  Inject, Injectable, Output } from '@angular/core';
import { ChatService } from './chat.service';
import {  SafeHtml } from '@angular/platform-browser';
import {  map } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { BehaviorSubject, interval, Subject, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../../models/user.model';
import { AuthService } from '../auth/auth.service';
import {ESendBy} from '../../models/EsendBy';
import { Observable } from 'rxjs';
import { chatbotmessage, Generic, Input, Option, UpdateFinderInformation, Value } from '../../models/chatbotmessage';
import { DOCUMENT, DatePipe } from '@angular/common';
import { Allmessages, Messages } from 'src/app/models/Allmessages';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbdPopupmodelDocContent } from 'src/app/Pages/listmodal/popupmodeldoc/popupmodeldoc.component';
import { NavigationStart, Router } from '@angular/router';

class testResult{
  result ;
   }
   class Photo{
    error ;
    url;
  }
class session {
  session_id ;
}




@Injectable({
  providedIn: 'root'
})
export class DataService {
  private _listners = new Subject<any>();
  private _listnersMessages$  = new Subject<any>();
  @Output()  testEsendby : ESendBy;
  public chatview :boolean = true;
  public chaturl :boolean = false ;
  public chatAlreadyStarted: boolean = false;
  ownerrepley:boolean=false;
  listmessages :  Messages[] ;
  content: SafeHtml;
  testbot : boolean = false;
  map: google.maps.Map;
  conversation = new BehaviorSubject<Message[]>([]);
  iframe: Iframe = new Iframe();
  interval:Subscription;

   // /**********list of api *************/
  private headers = new HttpHeaders({'Content-Type': 'application/json' ,'session_id': '294f2be8-c11d-4431-9853-1a611c5ebfeb'});
  private options = { headers: this.headers };
  private urlNgrok = "/create_chat_bot_session";
  private urlSend = "/Send_user_Response";
  private urlsendmessage = "/send_message_event_v2?";

  /**** fin list  */
  public image : any ;
  public sessionid : string;
  public userMessage : Message;
  public data : Generic[] ;
  public messagedata:Generic ;
  public collapsedUser:boolean =false ;
  list = new Array(0);
  botnum:number=0;
  datenow:any;
  testsend : boolean = false;
  chatbegin:boolean=false;
  private _listners$ = new Subject<any>();
  private _listnersmap$ = new Subject<any>();
  public onPause: any;
  public numberMessages:number = 0;

  redirectToMedicalInfo: string = '';
  redirectToMedicalInfoChange = new BehaviorSubject<string>('');

  launchEventValue: string = '';
  launchEventValueChange = new BehaviorSubject<string>(null);

  finder_chattimer_select_finder_option: number = 15
  finder_chattimer_skip_user_input: number =	12
  finder_chattimer_skip_video_audio: number =	20
  finder_chattimer_pause: number =	3;//5
  finder_chattimer_pause_1s: number =	0.5;//5
  //isPositionAutorised: boolean = false;
  loadingFile: boolean = false;
  isFirstMessage: boolean = true;
  isInMedicalInfo: boolean = false;
  intervalToClearMessagesSaved:Subscription;
  numberDiscussionMessages: number = 0;
  allConversation: Message[] = [];
  numberOfEssaie: number = 0;
  lastMessageSent: Message[] = [];

  constructor( private loaderService: LoaderService,private socketService: SocketService, @Inject(DOCUMENT) private document: Document ,private http:HttpClient,private chatService: ChatService,private authService : AuthService,private modalService: NgbModal,
  private datePipe: DatePipe, private _sharedService: SharedService, private router: Router) {
    let browserRefresh = !router.navigated;
      router.events.subscribe((event) => {
          if (event instanceof NavigationStart) {
             browserRefresh = !router.navigated;
          }
      });
      //if(browserRefresh) localStorage.removeItem('lastMessageSent');
      this.interval = interval(6000).subscribe(()=>{
          //localStorage.removeItem('lastMessageSent');
          this.lastMessageSent = [];
      });
      this.interval = interval(5000).subscribe(()=>{
        localStorage.removeItem('lastCommandSended');
      });
      /* if (navigator.geolocation ) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.isPositionAutorised = true
          });
        } */
      }

  updateUser(idfinder: string, updateFinder: UpdateFinderInformation, navigatorLanguage?: number|string){


    let bodyParams = {
      'params': updateFinder
    }
  return this.http.request<any>('post',`${environment.apiUrl}`+"/update_finder_info_v2?id_finder="+idfinder+"&id_language="+navigatorLanguage || 'fr',{
    body: bodyParams
  } )
  .pipe();
  }

    sendFinderInfoEmail(idfinder: string){
      let queryParameters = new HttpParams();
      queryParameters = queryParameters.append('id_finder', idfinder);
      return this.http.request<any>('get',`${environment.apiUrl}`+"/send_finder_info_email",{
        params: queryParameters
      });
    }

  delay(ms: number, pause) {
    this.onPause = pause;
    return new Promise( resolve => setTimeout(resolve, ms*1000) ).then(()=>{this.loaderService.hide()});
  }

  listenVideocall(): Observable<any> {
    return this._listners$.asObservable();
  }



  pushVideocall(filterBy: any) {
    this._listners$.next(filterBy);
  }

  typingmessage(message:string)
  {
    const currentUser = this.authService.currentUserValue;
    return this.http.request<any>('post', `${environment.apiUrl}/typing_status?receiver_zulip_mail=`+currentUser?.tag_creator?.zulip_mail+'&op='+message)
    .pipe(map(val => {

      return val;
    }));


  }

  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  listenMessages(): Observable<any> {
    return this._listnersMessages$.asObservable();
  }

  pushcount(num: number) {
    this._listners.next(num);
  }

  createTemplate(medicalObject: any ,id_user:string ,email:string) {

    return this.http.request<any>('post', `${environment.apiUrl}/api_print_export?id_user=`+id_user+`&id_language=38&lost_on=null&location=null&last_seen_wearing_app=null&email=`+ email +`&poster=0&pdf=1&obj_type=user`,{
      body: medicalObject
    })
    .pipe(map(val => {

      return val;
    }));

  }

  ngOnDestroy(){
    this.botnum =0;
}
  public async  converse(msg:any, typeContact: string)
  {
    //const userObject = this.authService.currentUserValue;
    let  emergency_contact: any =  JSON.parse(localStorage.getItem('emergency_contact'));
    if (typeContact == 'emergency_contact' && emergency_contact?.type_user == 'emergency_contact')
    {
    this.ownerrepley = true ;
    this.changeInputEmerg(msg);
    }
    else if(typeContact == 'finder')
    this.changeInput(msg);

    if ( !this.ownerrepley)
    {
      //this._sharedService.detectMessages.next(false);
      let messageValue = msg.value;
      if(this.iframe.finder_chattimer_skip_user_input ||  this.iframe.cmd_video_end || this.iframe.cmd_count_finder_chattimer_select_finder_option){
        this.interval?.unsubscribe()
        this.iframe.cmd_count_finder_chattimer_select_finder_option =false;
        this.iframe.finder_chattimer_skip_user_input = false;
        this.iframe.cmd_video_end = false
      }

      if ( !this.collapsedUser && (messageValue.trim() != ''|| msg?.type =="location" ))
      {
          if(this.iframe.cmd_item_picture_input && msg.type == 'file' && messageValue != 'skip' ){
            messageValue = this.iframe.item_picture_input;
            this.iframe.cmd_item_picture_input = false;
          }
          else if(this.iframe.cmd_share_position  && msg.type == 'location' && messageValue != 'skip'){
            messageValue = this.iframe.share_position;
            this.iframe.cmd_share_position = false;
          }
          if(localStorage.getItem('isOwnerReply') == "false"){
            if(messageValue == 'item_picture_input' && this.loadingFile){
              await new Promise( resolve => setTimeout(resolve, 4000));
            }
            if(msg?.type == "url") await this.delay(3, null);


          await this.chatService.sendMessage(this.urlSend,  messageValue,this.sessionid  ).then(async (res : chatbotmessage) =>
          {
            if(res?.texterror?.includes('400')){
              localStorage.setItem("sessionid",'undefined');
              this.init()
            }
            //this.onSendMessage(msg.label);
            this.numberMessages++;

              if ( res.output)
                this.data = res.output.generic;
              else
              {
                localStorage.setItem("sessionid",undefined);
                this.init();
                return;
              }
              if(this.data?.length == 0) this._sharedService.detectMessages.next(true);

              if( this.iframe.cmd_video_end || this.iframe.finder_chattimer_skip_user_input || this.iframe.finder_chattimer_skip_video_audio || this.iframe.cmd_count_finder_chattimer_select_finder_option){
                this.interval?.unsubscribe
                this.iframe.cmd_video_end = false;
              }
                const currentUser = this.authService.currentUserValue;
                let userid = localStorage.getItem("userId");

                let lost_item_info = '';
                if(res.context?.skills?.['main skill']?.user_defined?.item_etablissement !== null){
                  lost_item_info += res.context?.skills?.['main skill']?.user_defined?.item_etablissement
                }
                if(res.context?.skills?.['main skill']?.user_defined?.item_location !== null){
                  lost_item_info += res.context?.skills?.['main skill']?.user_defined?.item_location
                }

                let updateFinderInformation: UpdateFinderInformation = new UpdateFinderInformation();
                !res.context?.skills?.['main skill']?.user_defined?.email ? null : updateFinderInformation.email = res.context?.skills?.['main skill']?.user_defined?.email
                !res.context?.skills?.['main skill']?.user_defined?.first_name ? null :  updateFinderInformation.first_name = res.context?.skills?.['main skill']?.user_defined?.first_name
                !res.context?.skills?.['main skill']?.user_defined?.last_name  ? null :  updateFinderInformation.last_name = res.context?.skills?.['main skill']?.user_defined?.last_name
                !res.context?.skills?.['main skill']?.user_defined?.name ? null :  updateFinderInformation.last_name = res.context?.skills?.['main skill']?.user_defined?.name
                updateFinderInformation.lng = null;
                updateFinderInformation.lat = null;
                !res.context?.skills?.['main skill']?.user_defined?.id_device ? null :  updateFinderInformation.id_device = res.context?.skills?.['main skill']?.user_defined?.id_device
                updateFinderInformation.lost_item_info = lost_item_info;
                !res.context?.skills?.['main skill']?.user_defined?.phone ? null :  updateFinderInformation.phone = res.context?.skills?.['main skill']?.user_defined?.phone

                updateFinderInformation.reward_request = 0;


                if(currentUser?.finder_info?.id)
                this.updateUser(currentUser?.finder_info.id, updateFinderInformation).pipe(first())
                .subscribe((value: User) => {});
                this.isInMedicalInfo = false;
                if (this.data &&this.data?.length > 0){
                      const speech = null ;
                      for (let i = 0; i < this.data?.length; i++){
                        let botMessage: Message;

                        if(this.searchMessageOnConversation(this.data[i]?.title|| this.data[i]?.text, this.lastMessageSent)) {
                          continue;
                        }

                        if (this.data[i]?.response_type == "text" ){
                          this._sharedService.detectMessages.next(false);
                          botMessage = new Message(speech, ESendBy.bot, null, this.data[i], null,null, null ,i );
                          this.update(botMessage);
                          this.onSendMessage(this.data[i]?.text, ESendBy.bot)
                          if(this.data[i]?.text != 'cmd_open_medical_informations')
                          await this.Sendmessagebot(this.data[i]?.text ,1).toPromise();
                          if(!this.isInMedicalInfo){
                            setTimeout(() => {
                              this.loaderService.show();
                            }, 1000);
                            await this.delay(this.finder_chattimer_pause, this.data[i]);
                          }
                        }
                        /* else  */if (this.data[i]?.response_type == "video" ){
                          this._sharedService.detectMessages.next(false);
                          this.Sendmessagebot("[video]:"+this.data[i].source,1).subscribe(
                            ()=>{
                              this.loaderService.show();
                              this.onSendMessage("[video]:"+this.data[i].source, ESendBy.bot)
                          },()=>{
                            this.loaderService.hide();
                          });
                        }
                        /* else  */if (this.data[i]?.response_type == "option" ){
                          this._sharedService.detectMessages.next(true);
                          let message="";
                          message+=this.data[i]?.title;
                          for (let j = 0; j < this.data[i].options?.length; j++){
                            message +="[option]"+this.data[i].options[j].value.input.text+"[label]"+this.data[i].options[j].label
                          }
                          botMessage = new Message(speech, ESendBy.bot, null, this.data[i], null,null, null ,i );
                          this.update(botMessage);
                          this.Sendmessagebot(message,1)?.subscribe(()=>{
                            this.onSendMessage(message, ESendBy.bot)
                            //this.onSendMessage(this.data[i]?.text, ESendBy.bot)
                          },()=>{
                          });
                        }
                        if (this.data[i]?.response_type != "pause"   &&  this.data[i]?.response_type != "iframe"){
                          if(this.data[i]?.response_type != "text" && this.data[i]?.response_type != "option" && this.data[i]?.text != 'cmd_open_medical_informations'){
                            try{
                              this._sharedService.detectMessages.next(false);
                              if ( this.data[i]?.text?.includes('cmd_owner_thank_msg')){
                                let sendername = currentUser?.tag_creator?.tag_creator_first_name !== null ?currentUser?.tag_creator?.tag_creator_first_name :"Owner";
                                botMessage = new Message(speech,sendername, null, this.data[i], null,null, null ,i );
                              }
                              else botMessage = new Message(speech, ESendBy.bot, null, this.data[i], null,null, null ,i );
                              this.update(botMessage);
                              this.Sendmessagebot(this.data[i]?.text,1)?.subscribe((data :testResult)=>{
                              this._sharedService.detectMessages.next(false);
                              },()=>{
                                this.loaderService.hide();
                              });
                              if(!this.isInMedicalInfo){
                                await this.delay(this.finder_chattimer_pause, this.data[i]);

                              }
                            } catch (e){
                            console.log(e);
                            }
                          }
                        }
                        /* if (this.data[i]?.response_type == "pause" )
                        {
                              await this.delay(this.data[i].time, this.data[i]);
                        } */
                        else if (this.data[i]?.response_type == "iframe" ){
                          this._sharedService.detectMessages.next(true);
                          if(this.data[i]?.title?.trim() == "cmd_open_medical_informations"){
                            this.isInMedicalInfo = true;
                            this.redirectToMedicalInformation('openMedicalInformations');
                          }
                          if(this.data[i]?.title?.trim() == "cmd_video_call"){
                            this.redirectToMedicalInformation('openVideoCall');
                          }
                          if(this.data[i]?.title?.trim() == "cmd_item_picture_input"){
                            this.iframe.cmd_item_picture_input = true;
                          }
                          if(this.data[i]?.title?.trim() == "cmd_share_position"){
                            this.iframe.cmd_share_position = true;
                          }
                          if(this.data[i]?.title?.trim() == "[cmd_video_end]"){
                            this.iframe.cmd_video_end = true;
                            this.sendTimer(63, this.iframe.chat_cmd_video_end)
                          }
                          /* if(this.data[i]?.title.trim() == "[cmd_count_finder_chattimer_Select_finder_option]"){
                              this.iframe.cmd_count_finder_chattimer_select_finder_option = true;
                              this.sendTimer(this.finder_chattimer_select_finder_option,
                                this.iframe.option_chat)
                            }
                            if(this.data[i]?.title.trim() == "[cmd_count_finder_chattimer_skip_user_input]" || this.data[i]?.title.trim() == "cmd_count_finder_chattimer_skip_user_input" ) {
                              this.iframe.finder_chattimer_skip_user_input = true;
                              this.sendTimer(this.finder_chattimer_skip_user_input,
                                this.iframe.chat_skip_user_input)
                            }
                            if(this.data[i]?.title.trim() == "[cmd_count_finder_chattimer_skip_video_audio]") {
                              this.iframe.finder_chattimer_skip_video_audio = true;
                              this.sendTimer(this.finder_chattimer_skip_video_audio,
                                this.iframe.skip_video_audio)
                            } */
                          }
                          if((i+1) == this.data?.length) this._sharedService.detectMessages.next(true);
                      }

                      let a = this.document.getElementById('scrollrow');
                      if(a)
                      {
                        a.scrollTop = a.scrollHeight;
                      }
                }
          },(error=>{
            localStorage.setItem("sessionid",'undefined');
            this.init();
          })
        );
          }
      }
    }
     return  this.data ;

  }

  public async sendTimer(time: number, text:string){
      this.interval = interval(
      //parseInt(time)*1000
      time*1000
      ).subscribe((data)=>{
          this.chatfirst(this.urlSend, text, 1)
          this.interval?.unsubscribe()
      });
  }


  public changeInputEmerg(target: any){
    var today = new Date();
    this.datenow=this.datePipe.transform(today, 'hh:mm a');

    if ( target.type ==="file")
    {
      let file: File = target.files[0];
      let formData:FormData = new FormData();
      formData.append('file', file, file.name);
      this.loaderService.show();

      this.upload_file(formData).subscribe((value: Photo) =>
         {
            if (value) {
              this.userMessage = new Message( null, ESendBy.user, value.url , null ,null,null,null,null,this.datenow);
              this.update(this.userMessage);
              this.onSendMessageEmergency('[photo]:'+value.url)
              this.SendmessageEmergency( '[photo]:'+value.url).subscribe(
                (data :testResult)=>{} ,
                error => { } );
                this.loaderService.hide();
            }
         },()=>{
          this.open("Problem when uploading photo, Please try again")
          this.loaderService.hide();
          return;
         });
    }
    else if ( target.value.includes('[pincode]:'))
    {
      const value = target.value//.replace('[pincode]:','');
     this.userMessage = new Message(value ,ESendBy.user,null ,null, null ,null,null,null,this.datenow);
     this.update(this.userMessage);
     this.onSendMessageEmergency(value);
     this.SendmessageEmergency(value).subscribe(
             (data :testResult)=>{});
    }
    else if ( target.type ==="url")
    {
     this.userMessage = new Message( null,ESendBy.user,null ,null,target.value ,null,null,null,this.datenow);
     this.onSendMessageEmergency('[link]:'+target.value);
     this.SendmessageEmergency('[link]:'+target.value ).subscribe(
             (data :testResult)=>{});

    this.update(this.userMessage);

    }
    else if ( target.type ==="location")
    {
      this.userMessage = new Message( target.value,ESendBy.user,null ,null, null, 'map',null,null,this.datenow);
      if(target.value === 'yes'){
        this.update(this.userMessage);
        this.onSendMessageEmergency("[position]:"+localStorage.getItem('latitudeEmergency')+","+localStorage.getItem('longitudeEmergency'))
        if(localStorage.getItem('latitudeEmergency') && localStorage.getItem('longitudeEmergency'))
        this.SendmessageEmergency("[position]:"+localStorage.getItem('latitudeEmergency')+","+localStorage.getItem('longitudeEmergency')).subscribe((data :testResult)=>{});

      }
    }
     else
    {
      if (target.name !=='Event')
      {
      this.userMessage = new Message( target.value, ESendBy.user,null ,null, null,null,null,null,this.datenow);
      this.update(this.userMessage);
      }
      if (target.name ==='Event')
      {
      this.changeuser();
      }
      this.SendmessageEmergency(target.value).subscribe((data :testResult)=>{});
    }
  }

  onSendMessage(text, sender: string){
    const currentUser = this.authService.currentUserValue;
    if(sender == 'bot') text = ':[bot]'+text;

    const message: OutGoingMessage = {
      room: currentUser?.tag_info?.tag_info.serial_number,
      message: text,
    };
    this.socketService.sendMessage(message);
  }

  onSendMessageEmergency(text){
    let  emergency_contact: any =  JSON.parse(localStorage.getItem('emergency_contact'));
    const message: OutGoingMessage = {
      room: emergency_contact?.sn,
      message: text,
    };
    this.socketService.sendMessage(message);
  }

  public async changeInput (target: any){
    const messageValue = target.value;
    var today = new Date();
    this.datenow=this.datePipe.transform(today, 'hh:mm a');


    if ( target.type ==="file")
    {
      let file: File = target.files[0];
      let formData:FormData = new FormData();
      formData.append('file', file, file.name);
      this.loadingFile = true;
      this.upload_file(formData).subscribe(async (value: Photo) =>
         {
            if (value) {
              this.userMessage = new Message( null, ESendBy.user, value.url , null ,null,null,null,null,this.datenow);
              this.update(this.userMessage);
                this.onSendMessage('[photo]:'+value.url, ESendBy.finder)
                this.Sendmessage( '[photo]:'+value.url).subscribe((data :testResult)=>{}, error => {});
                this.loadingFile = false;
            }
         },()=>{
            this.loadingFile = false;
            this.open("Problem when uploading photo, Please try again")
            return;
         });
    }
    else if ( target.type ==="url")
    {
     this.userMessage = new Message( null,ESendBy.user,null ,null,target.value ,null,null,null,this.datenow);
      this.update(this.userMessage);
      this.onSendMessage('[link]:'+target.value, ESendBy.finder);
      this.Sendmessage('[link]:'+target.value ).subscribe();
    }
    else if ( target.type ==="location")
    {
      this.userMessage = new Message( target.value,ESendBy.user,null ,null, null, 'map',null,null,this.datenow);
      if(target.value === 'share_localisation'){
        this.update(this.userMessage);

        this.onSendMessage("[position]:"+localStorage.getItem('latitude')+","+localStorage.getItem('longitude'), ESendBy.finder);
        if(localStorage.getItem('latitude') && localStorage.getItem('longitude'))
        this.Sendmessage("[position]:"+localStorage.getItem('latitude')+","+localStorage.getItem('longitude')).subscribe((data :testResult)=>{});
      }
    }
     else
    {
      if (target.name !=='Event')
      {//CHECK HERE
      this.userMessage = new Message( target.value, ESendBy.user,null ,null, null,null,null,null,this.datenow);
      this.update(this.userMessage);
      }
      if (target.name ==='Event')
      {
      this.changeuser();
      }

      const currentUser = this.authService.currentUserValue;
      if ( currentUser != null && (localStorage.getItem('user_type') == undefined || localStorage.getItem('user_type') != 'emergency_contact'))
     // setTimeout(() => {
        this.Sendmessage(target.label!== undefined ? target.label : messageValue  ).subscribe((data :testResult)=>{});
     // }, 1000);
      /* if(localStorage.getItem('user_type') == 'emergency_contact')
      this.SendmessageEmergency(target.label!== undefined?target.label:target.value  ).subscribe((data :testResult)=>{}); */
    }
  }

  ChangeSender(){
    this.testEsendby = ESendBy.owner;
     return this.testEsendby;
  }

  public  converseOwner( target:any ,sender:string , typeUser?: string ) {

    var userMessage;
    var today = new Date();
    this.datenow = this.datePipe.transform(today, 'hh:mm a');
    if ( target.type === 'image')
    {
     userMessage = new Message(null, sender, target.value ,null, null,null,null,null ,this.datenow, null, null, null , typeUser);
    }
    else if ( target.type === 'url')
    {
     userMessage = new Message(null, sender, null ,null, target.value,null,null,null,this.datenow, null, null, null , typeUser);
    }
    else if ( target.type === 'location')
    {
      userMessage = new Message(null, sender , null ,null,null,target.value,null,null,this.datenow, null, null, null , typeUser);

    }
    else
    {

      userMessage = new Message(target.value,sender, null ,null,null,null,null,null,this.datenow, null, null, null , typeUser);

    }
    this.update(userMessage);
  }



  public  conversebot( target:any ,sender:string  ,num:number ) {
    var userMessage;
    var today = new Date();
    this.datenow=this.datePipe.transform(today, 'hh:mm a');
    if ( target.type === 'image')
    {
     userMessage = new Message(null, sender, target.value ,null, null,null,null,num ,this.datenow);
    }
    else if ( target.type === 'url')
    {
     userMessage = new Message(null, sender, null ,null, target.value,null,null,num,this.datenow);
    }
    else if ( target.type === 'location')
    {
      userMessage = new Message(null, sender , null ,null,null,target.value,null,num,this.datenow);

    }
    else
    {

      userMessage = new Message(target.value,sender, null ,null,null,null,null,num,this.datenow);

    }

    this.update(userMessage);
  }

  public update(msg: Message) {
    if(msg.sendBy !== 'server'){
      this.allConversation.push(msg);
      this.lastMessageSent.push(msg);
      this.numberDiscussionMessages++
    }
    //if(msg.sendBy !== 'user')
    this.conversation.next([msg]);
    //this.changeuser();

  }

  changeuser(){
    setTimeout(() => { const chatinput = document.querySelector('Chat-bot');
  },4000);

  }

  onClick(event) {}

  public async chatfirst(url : string ,msg: string , to_send_notification:number ) {
    this._sharedService.detectMessages.next(false);

    if(localStorage.getItem('lastCommandSended') == msg){
      localStorage.setItem('lastCommandSended', msg)
      return;
    }
    else{
      localStorage.setItem('lastCommandSended', msg)
      this.chatfirst("/Send_user_Response",msg , 0);
    }

    this.chatService.sendMessage(url, msg, this.sessionid ).then(async (res : any) => {
     if ( res.output!=null ) {
      this.data = res.output.generic;
      if(this.data?.length == 0) this._sharedService.detectMessages.next(true);
          const speech = null;
          if(this.iframe.finder_chattimer_skip_user_input || this.iframe.cmd_count_finder_chattimer_select_finder_option || this.iframe.finder_chattimer_skip_video_audio){
            this.interval?.unsubscribe()
            this.iframe.cmd_count_finder_chattimer_select_finder_option = false;
            this.iframe.finder_chattimer_skip_user_input = false;

          }
          if ( this.data != undefined)
           for (let i = 0; i < this.data?.length; i++)
            {
              /* if(isDuplicatedMessage == this.data[i]?.text) continue;
              if(this.data[i]?.text) isDuplicatedMessage = this.data[i]?.text; */
              if(this.searchMessageOnConversation(this.data[i]?.title|| this.data[i]?.text, this.lastMessageSent)) {
                continue;
              }

              if(!this.isFirstMessage && this.data[i]?.response_type != 'pause'){
                this.loaderService.show();
                if(this.data?.length>1)
                await this.delay(this.finder_chattimer_pause, this.data[i]);
                else
                await this.delay(this.finder_chattimer_pause_1s, this.data[i]);
                this.loaderService.hide();
                this.isFirstMessage = false;
              }else this.isFirstMessage = false;


              if (this.data[i]?.response_type != "pause"  &&  this.data[i]?.response_type != "iframe" ){
                  this.collapsedUser = false ;
                  let textMessage: string = this.data[i]?.title || this.data[i]?.text;
                  // this._sharedService.detectMessages.next(false);
                  // const botMessage = new Message(speech, ESendBy.bot, null, this.data[i], null,null, null ,i);
                  //this.update(botMessage);
                /* if(this.data[i]?.text != null){
                  this._sharedService.detectMessages.next(false);
                  this.onSendMessage(this.data[i]?.text)
                  const botMessage = new Message(speech, ESendBy.bot, null, this.data[i], null,null, null ,i);
                  this.update(botMessage);
                }else if(this.data[i]?.title != null){
                  this._sharedService.detectMessages.next(false);
                  this.onSendMessage(this.data[i]?.title)
                  const botMessage = new Message(speech, ESendBy.bot, null, this.data[i], null,null, null ,i);
                  this.update(botMessage);
                } */
                 if (this.data[i]?.response_type == "text" ){
                  let text = this.data[i]?.text || this.data[i]?.title;
                  this._sharedService.detectMessages.next(false);
                  this.Sendmessagebot(text , to_send_notification)?.subscribe(
                    (response :testResult)=>{});
                  const botMessage = new Message(speech, ESendBy.bot, null, this.data[i], null,null, null ,i);
                  this.update(botMessage);
                  this.onSendMessage(textMessage, ESendBy.bot);
                }
                else if (this.data[i]?.response_type == "image" ){
                  this._sharedService.detectMessages.next(false);
                  this.Sendmessagebot(this.data[i].source,to_send_notification)?.subscribe(
                    (data :testResult)=>{});
                    const botMessage = new Message(speech, ESendBy.bot, null, this.data[i], null,null, null ,i);
                  this.update(botMessage);
                  this.onSendMessage(textMessage, ESendBy.bot);
                }
                else if (this.data[i]?.response_type == "option"){
                  this._sharedService.detectMessages.next(true);
                  let message="";
                  message+=this.data[i]?.title;
                  for (let j = 0; j < this.data[i].options?.length; j++){
                    message += "[option]"+ this.data[i].options[j].value.input.text+"[label]"+this.data[i].options[j].label
                  }
                  this.Sendmessagebot(message,to_send_notification)?.subscribe((data :testResult)=>{ });
                  const botMessage = new Message(speech, ESendBy.bot, null, this.data[i], null,null, null ,i);
                  this.update(botMessage);
                  this.onSendMessage(textMessage, ESendBy.bot);
                }
              }
               /*if (this.data[i]?.response_type == "pause" )
              {
                await this.delay(this.data[i].time, this.data[i]);
              }*/
              else if (this.data[i]?.response_type == "iframe" )
              {
                this._sharedService.detectMessages.next(false);
                if(this.data[i]?.title?.trim() == "cmd_open_medical_informations"){
                  this.redirectToMedicalInformation('openMedicalInformations');
                }
                if(this.data[i]?.title?.trim() == "cmd_video_call"){
                  this.redirectToMedicalInformation('openVideoCall');
                }
                if(this.data[i]?.title?.trim() == "cmd_item_picture_input"){
                  this.iframe.cmd_item_picture_input = true;
                }
                if(this.data[i]?.title?.trim() == "cmd_share_position"){
                  this.iframe.cmd_share_position = true;
                }

                if(this.data[i]?.title?.trim() == "[cmd_video_end]"){
                  this.iframe.cmd_video_end = true;
                  this.sendTimer(60,
                    this.iframe.chat_cmd_video_end)
                }
                if(this.data[i]?.title?.trim() == "[cmd_set_variables]"){
                  this.chatfirst(this.urlSend ,"owner_thank_msg_set", 1 )
                }
                //this.loaderService.hide();
                /* if(this.data[i]?.title.trim() == "[cmd_count_finder_chattimer_Select_finder_option]"){
                  this.iframe.cmd_count_finder_chattimer_select_finder_option = true;
                  this.sendTimer(this.finder_chattimer_select_finder_option,
                    this.iframe.option_chat)
                }
                if(this.data[i]?.title.trim() == "[cmd_count_finder_chattimer_skip_user_input]" || this.data[i]?.title.trim() == "cmd_count_finder_chattimer_skip_user_input" ) {
                  this.iframe.finder_chattimer_skip_user_input = true;
                  this.sendTimer(this.finder_chattimer_skip_user_input,
                    this.iframe.chat_skip_user_input)
                }
                if(this.data[i]?.title.trim() == "[cmd_count_finder_chattimer_skip_video_audio]") {
                  this.iframe.finder_chattimer_skip_video_audio = true;
                  this.sendTimer(this.finder_chattimer_skip_video_audio,
                    this.iframe.skip_video_audio)
                } */
              }
              if((i+1) == this.data?.length) this._sharedService.detectMessages.next(true);
            }
       }

       else if ( res.message ==="Unknown error")
       this.open("Vous ne pouvez pas consulter les messages!")
    },()=>{
      this.loaderService.hide();
    });

  }

  createsession(assistant_id:any ) : Observable<any> {
    // "32e869c7-520a-4132-9ed8-6019b7e16943"
    return this.http.get(`${environment.apiUrl}`+this.urlNgrok +"?assistant_id="+assistant_id, this.options);
  }

  open(content) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
     windowClass : "myCustomModalClass",

    };

    const modeldoc =this.modalService.open(NgbdPopupmodelDocContent,options);
    modeldoc.componentInstance.chatinfo = true;
    modeldoc.componentInstance.number = 1;
    modeldoc.componentInstance.title =content;
    modeldoc.componentInstance.texte="finder_response_titleerror";
    modeldoc.componentInstance.color="#F4C809";
    modeldoc.componentInstance.type="error";
    modeldoc.componentInstance.event.subscribe((data:any) => {

      modeldoc.close();
    });
  }


  public init(): any{
    let sessionid = localStorage.getItem("sessionid");
    /* this.conversation.subscribe((data)=>{
      console.log(data);

    })
    this.conversation  = new BehaviorSubject<Message[]>([]);
    this.conversation.subscribe((data)=>{
      console.log(data);

    }) */
    const currentUser = this.authService.currentUserValue;
    let assistant_id ;

    if ( currentUser !==null)
    {

    for(let data of currentUser.chatbot_assitant )
      {
          if (localStorage.getItem('jobpage')  === 'lostobject' && data.job ==="object")
          {
                assistant_id = data.id;
          }
          else if (localStorage.getItem('jobpage')  === 'child' && data.job ==="child")
          {
                assistant_id = data.id;
          }
          else if ( localStorage.getItem('jobpage')  === 'lostmedical' && data.job ==="medical")
          {
                assistant_id = data.id;
          }

    }
    if (assistant_id === undefined || assistant_id === null )
    assistant_id = currentUser.chatbot_assitant[0]?.id;
    }

    /* if(localStorage.getItem('oldChatbot') !== localStorage.getItem('currentChatbot'))
    localStorage.setItem('oldChatbot', localStorage.getItem('currentChatbot')) */

    if (!sessionid || sessionid === "undefined") // "testemergency"
    {

        localStorage.setItem('oldChatbot', localStorage.getItem('currentChatbot'))
        localStorage.setItem("assistant_id" ,assistant_id) ;
        if(localStorage.getItem('ischatUrl') !== 'ischatUrl')
        this.Sendmessage("[newdiscussion]")?.subscribe((data :testResult)=>{});
        this.numberOfEssaie++

        if(localStorage.getItem('ischatUrl') !== 'ischatUrl')
        return this.createsession(assistant_id).subscribe(
          (data :session)=>
          {
            localStorage.setItem("sessionid",data.session_id);
            this.sessionid = data.session_id;
            let intialCommand = '';
            if (localStorage.getItem('jobpage') === "child")
            {
              intialCommand = 'lost_person_chatbot';
            }
            else if ( localStorage.getItem('jobpage') === "lostmedical")
            {
              intialCommand = 'medical_emergency_chatbot';
            }

            this.chatfirst("/Send_user_Response",intialCommand , 0);

          },error => {
            if(this.numberOfEssaie > 5)
            /* this.retryScan();
            else */
            this.init()
          }

        ),error => {
            //alert(error.message)
          }
          else return null;


    }
    else if ( sessionid !== "undefined" &&  localStorage.getItem("SerialNumber")?.toUpperCase() ===  this.authService?.currentUserValue?.tag_info.tag_info?.serial_number.toUpperCase() && assistant_id === localStorage.getItem("assistant_id")
    && localStorage.getItem('oldChatbot') == localStorage.getItem('currentChatbot') && (localStorage.getItem('oldChatbot') != '' || localStorage.getItem('currentChatbot') != ''))
    {
        this.sessionid = sessionid;
        return null ;
    }
  }

  public getmessages(idUser: string, serialNumber: string, type_user: string){
    this._listnersMessages$.next(true);
    this._sharedService.detectMessages.next(true);
    this.loaderService.show();
    this.GetAllMessage(idUser, serialNumber, type_user).subscribe(
      (data :Allmessages)=>{
       try{
        this.loaderService.hide();
        this._listnersMessages$.next(false);
        let sender;
       // this.numberDiscussionMessages = data.dicussion.length;

        this.listmessages = [];
        for (let index = 0; index < data.dicussion?.length; index++) {
          //const element = data.dicussion[index];
          if( data.dicussion[index].content.includes('[newdiscussion]') !== true){
            this.listmessages.push(data.dicussion[index]);
          }
        }

       let i = 0 ;
       this.botnum=0;
       /* if(this.listmessages.length  == 0)
       this.init(); */

        while ( this.listmessages?.length  > i)
        {
          let message = this.listmessages[i];

          /* if(this.searchMessageOnConversation(message?.content, this.lastMessageSent)) {
            i++;
            continue;
          } */


          if ( message.sender_email!== "welcome-bot@zulip.com")
           {
            this.messagedata = new Generic;
            this.messagedata.typing=false;
            this.messagedata.text=message.content.replace('<p>','').replace('</p>','')
            this.messagedata.title=message.content.replace('<p>','').replace('</p>','')
            this.messagedata.response_type = 'text';
            var theDate = new Date(message.timestamp * 1000);
            let dateString = this.datePipe.transform(theDate, 'hh:mm a');
             if ( message.sender_full_name==="Found.me" || (message.sender_full_name==='Finder' && this.chaturl))
              {

                if(message.sender_full_name==="Found.me")
                sender= ESendBy.bot ;
                else if (message.sender_full_name==="Finder")
                sender= ESendBy.finder ;

                  if ( message.content.includes("[photo]:"))
                  {
                  this.userMessage = new Message(null,sender, message.content.replace("[photo]:",""), null, null,null, null ,  this.botnum,dateString);

                  }
                  else if ( message.content.includes("[url]:")  )
                  this.userMessage = new Message(null, sender,  message.content.replace("[url]:","") ,null, message.content.replace("[url]:",""),null,null, this.botnum,dateString);
                   else if ( message.content.includes("[video]:")  )
                   {
                    message.content = message.content.replace('[video]: ','')
                  this.userMessage = new Message(null, sender,null,null, null,null, message.content, this.botnum,dateString);

                    }
                     else if ( message.content.includes("[pincode]:"))
                  {
                    let serial = localStorage.getItem("SerialNumber")
                    var pincode =message.content.replace("[pincode]:","")
                    localStorage.setItem("pincode",pincode)
                          this.authService.Getemergency(serial,pincode)
                          .subscribe((data :any)=>{

                    });
                  }
                  else if  ( message.content.includes("[position]:"))
                  {
                    //if(this.isPositionAutorised){
                      this.userMessage = new Message(null,sender, null, null,null,this.messagedata.text.replace("[position]:",""), null ,  this.botnum,dateString);
                    //}
                  }
                  else if  ( message.content.includes("[title]"))
                  {
                    this.messagedata =new Generic;
                    let substrings = message.content.split('[option]');
                    this.messagedata.title = substrings[0].replace('[title]','').replace('<p>','').replace('</p>','');
                    this.messagedata.response_type="option";
                    this.messagedata.options = [];

                    for ( let j=1 ; j<substrings?.length ;j++)
                    {
                    let option = new Option ();
                    let substr = substrings[j].split('[label]');
                    option.value = new Value ;
                    option.value.input = new Input ;
                    option.value.input.text =substr[0].replace('<p>','').replace('</p>','')
                    option.label =substr[1].replace('<p>','').replace('</p>','')
                    this.messagedata.options.push (option);

                    }

                    this.userMessage = new Message(message.content, ESendBy.bot, null,this.messagedata, null,null, null, this.botnum ,dateString );

                  }
                  else if  ( message.content.includes("[link]:"))
                  this.userMessage= new Message(null, message.sender_full_name, null ,null, message.content.replace("[link]:","") ,null,null,null,dateString);

                  else if(message.content.includes("[option]")){
                    let content =  message.content;

                    let listSplit: any[]=[];
                    let options: Option[]=[];


                    content = content.replace('<p>', '')
                    content = content.replace('</p>', '')
                    listSplit = content.split('[option]')
                    for (let index = 0; index < listSplit?.length; index++) {
                      if(listSplit[index].includes('[label]')){
                        let option = new Option();
                        let newValue = new Value();
                        let newInput = new Input();
                        let label = '';
                        let value = '';

                        label = listSplit[index].substring(0, listSplit[index].indexOf('[label]'))
                        value = listSplit[index].substring(listSplit[index].indexOf('[label]')+7)

                        option.label = value;

                        newInput.text = label;

                        newValue.input = newInput;

                        option.value = newValue;

                        options.push(option);
                      }
                    }
                    let newGeneric: Generic = new Generic();
                    newGeneric.response_type = 'option';
                    newGeneric.title = listSplit[0];
                    newGeneric.options = options;

                    this.userMessage= new Message(null, sender, null ,newGeneric, null ,null,null, this.botnum,dateString);
                  }
                  else{
                    if( !message.content.includes('cmd_open_medical_informations')){
                      this.userMessage= new Message(this.messagedata.text,sender, null, this.messagedata, null,null, null ,  this.botnum,dateString);
                      }
                  }

                this.update(this.userMessage);
                this.botnum++;
            }
             else if ( message.sender_full_name===('Finder') && !this.chaturl)
            {
              this.botnum=0;
              sender= ESendBy.user  ;

              if ( message.content.includes("[photo]:")){
                this.userMessage = new Message(null,sender, message.content.replace("[photo]:",""), null, null,null, null , null,dateString);
                this.update(this.userMessage)
              }
              else if ( message.content.includes("[pincode]:"))
              {
                var pincode = message.content.replace("[pincode]:","")
                let serial = localStorage.getItem("SerialNumber")
                this.authService.Getemergency(serial,pincode)
                      .subscribe((data :any)=>{

                });
              }
              else if ( message.content.includes("[newdiscussion]:"))
              {
                var pincode = message.content.replace("[pincode]:","")
                let serial = localStorage.getItem("SerialNumber")
                this.authService.Getemergency(serial,pincode)
                      .subscribe((data :any)=>{

                });
              }
              else if ( message.content.includes("[url]:")){
                this.userMessage = new Message(null, sender, null ,null, message.content.replace("[url]:",""),null,null,null,dateString);
                this.update(this.userMessage)
              }
              else if ( message.content.includes("[video]:"))
              this.userMessage = new Message(null, sender, message.content.replace("[video]:","") ,null, null,null,null,null,dateString);
              else if  ( message.content.includes("[position]:"))
              {
                //if (this.isPositionAutorised) {
                  this.userMessage = new Message(null,sender, null, null,null,this.messagedata.text.replace("[position]:",""), null , null,dateString);
                //}
              }
              else if  ( message.content.includes("[link]:")){
                this.userMessage= new Message(null, sender, null ,null, message.content.replace("[link]:","") ,null,null,null,dateString);
                this.update(this.userMessage);
              }

              else
              this.userMessage= new Message(this.messagedata.text,sender, null, null, null,null, null , null,dateString);

              if(this.userMessage.content || this.userMessage.data || this.userMessage.location)
              this.update(this.userMessage);


             }

             else
             {

              this.botnum=0;
              let emergencyContact: any = JSON.parse(localStorage.getItem('emergency_contact'));
              if(type_user == 'emergency_contact' && message.sender_email.includes("@emer") && emergencyContact?.zulip_id == message.sender_id?.toString())
              sender= 'user';
              else
              sender= message.sender_full_name;

              /* if(this.searchMessageOnConversation(message?.content, this.lastMessageSent) && !message?.content.includes('[position]:')) {
                i++;
                continue;
              } */

              let typeUser: string ='';
              if(message.sender_email.includes("@emer")){
                typeUser = "emergency_contact";
              }
              else if(message.sender_email.includes("@user")){
                typeUser = "owner";
              } else if(message.sender_email.includes("@finder")){
                typeUser = "finder";
              }

              if ( message.content.includes("[photo]:")){
                this.userMessage = new Message(null,sender, message.content.replace("[photo]:",""), null, null,null, null , null,dateString, null, null, null, typeUser);
                this.update(this.userMessage);
              }
              else if ( message.content.includes("[url]:")){
                this.userMessage = new Message(null, sender, null ,null, message.content.replace("[url]:",""),null,null,null,dateString, null, null, null,typeUser);
                this.update(this.userMessage);
              }
              else if  ( message.content.includes("[position]:"))
              {
                //if (this.isPositionAutorised ) {
                  this.userMessage = new Message(null,sender, null, null,null,this.messagedata.text.replace("[position]:",""), null , null,dateString, null, null, null,typeUser);
                //}
              }
              else if ( message.content.includes("[video]:"))
              this.userMessage = new Message(null, sender, message.content.replace("[video]:","") ,null, null,null,null,null,dateString, null, null, null,typeUser);

              else if  ( message.content.includes("[link]:")){
                this.userMessage= new Message(null, sender, null ,null, message.content.replace("[link]:","") ,null,null,null,dateString, null, null, null,typeUser);
                this.update(this.userMessage);
              }
              else{
                if( !message.content.includes('cmd_open_medical_informations')){
                  this.userMessage = new Message(this.messagedata.text,sender, null, null, null,null, null ,  null,dateString , null, null, null,typeUser);
                }
              }
              if(this.userMessage.content || this.userMessage.data || this.userMessage.location)
              this.update(this.userMessage);
             }

           }
           i++;
        }




       }
        catch (err){
          this.init()
        }
      },
      error=> {
        this.init()

      }
    );

  }

  searchMessageOnConversation(message: string, conversation: Message[]): boolean{
    const value = conversation.some((element) => {
      if(message)
      return (message?.includes(element?.data?.title) || message?.includes(element?.data?.text));
      else return false;
    });
    return value;
  }

  /* private searchMessageOnConversationOnLoad(message: string, messageId,conversation: Message[]): boolean{
    const value = conversation.some((element) => {
      if(message)
      return ((message?.includes(element?.data?.title)) || message?.includes(element?.data?.text));
      else return false;
    });
    return value;
  } */

  public getmessageschaturl(idUser: string, serialNumber: string){
    this._listnersMessages$.next(true);
    this.GetAllMessage(idUser, serialNumber, "").subscribe(
      (data :Allmessages)=>{
       try{
        this._listnersMessages$.next(false);
        let sender

        let j = data.dicussion?.length-1;
        this.listmessages = [];
        while ( data.dicussion[j].content.includes('[newdiscussion]') !== true )
        {
          this.listmessages.unshift(data.dicussion[j]);
          j--;
        }
        let i = 0 ;
        while ( this.listmessages?.length  > i)
        {
          let message = this.listmessages[i];

          if ( message.sender_email!== "welcome-bot@zulip.com")
           {
            this.messagedata = new Generic;
            this.messagedata.typing=false;
            this.messagedata.text=message.content.replace('<p>','').replace('</p>','')
            this.messagedata.title=message.content.replace('<p>','').replace('</p>','')
            this.messagedata.response_type = 'text';
            var theDate = new Date(message.timestamp * 1000);
            let dateString = this.datePipe.transform(theDate, 'hh:mm a');
             if ( message.sender_full_name==="foundy")
              {
                sender= ESendBy.bot ;
                  if ( message.content.includes("[photo]:"))
                  {
                    this.userMessage = new Message(null,sender, message.content.replace("[photo]:",""), null, null,null, null ,  this.botnum,dateString);
                    this.update(this.userMessage);
                    this.botnum++;
                  }
                    else if ( message.content.includes("[url]:"))
                  {
                    this.userMessage = new Message(null, sender, null ,null, message.content.replace("[url]:",""),null,null, this.botnum,dateString);
                    this.update(this.userMessage);
                    this.botnum++;
                  }
                  else if ( message.content.includes("[video]:"))
                  {
                      this.userMessage = new Message(null, sender,null ,null, null,null, message.content.replace("[video]:",""),null,dateString);
                      this.update(this.userMessage);
                      this.botnum++;
                    }
                  else if ( message.content.includes("[pincode]:"))
                  {
                    let serial = localStorage.getItem("SerialNumber")
                    var pincode =message.content.replace("[pincode]:","")
                          this.authService.Getemergency(serial,pincode)
                          .subscribe((data :any)=>{

                    });
                  }
                  else if  ( message.content.includes("[position]:"))
                  {
                    this.userMessage = new Message(null,sender, null, null,null,this.messagedata.text.replace("[position]:",""), null ,  this.botnum,dateString);
                    this.update(this.userMessage);
                    this.botnum++;
                  }
                  else if  ( message.content.includes("[title]"))
                  {
                    this.messagedata =new Generic;
                    let substrings = message.content.split('[option]');
                    this.messagedata.title = substrings[0].replace('[title]','').replace('<p>','').replace('</p>','');
                    if ( this.messagedata.title )
                  {
                    this.userMessage = new Message( this.messagedata.title, ESendBy.bot, null,null, null,null, null, this.botnum ,dateString );
                    this.update(this.userMessage);
                    this.botnum++;
                  }
                    for ( let j=1 ; j<substrings?.length ;j++)
                    {
                    let option = new Option ();
                    let substr = substrings[j].split('[label]');
                    option.label =substr[1].replace('<p>','').replace('</p>','')
                    this.userMessage = new Message(option.label, ESendBy.bot, null,null, null,null, null, this.botnum ,dateString );
                    this.update(this.userMessage);
                    this.botnum++;
                    }


                  }
                  else if  ( message.content.includes("[link]:"))
                  {
                    this.userMessage= new Message(null, sender, null ,null, message.content.replace("[link]:","") ,null,null, this.botnum,dateString);
                    this.update(this.userMessage);
                    this.botnum++;
                  }
                  else
                  {
                    this.userMessage= new Message(this.messagedata.text,sender, null, this.messagedata, null,null, null ,  this.botnum,dateString);
                    this.update(this.userMessage);
                    this.botnum++;
                  }


              }
              else if ( message.sender_email === localStorage.getItem("owner_zulip_mail"))
              {

              this.botnum=0;
              sender= ESendBy.user  ;
              if ( message.content.includes("[photo]:"))
              this.userMessage = new Message(null,sender, message.content.replace("[photo]:",""), null, null,null, null , null,dateString);
              else if ( message.content.includes("[pincode]:"))
              {
                var pincode = message.content.replace("[pincode]:","")
                let serial = localStorage.getItem("SerialNumber")
                this.authService.Getemergency(serial,pincode)
                      .subscribe((data :any)=>{

                });
              }
              else if ( message.content.includes("[newdiscussion]:"))
              {
                var pincode = message.content.replace("[pincode]:","")
                let serial = localStorage.getItem("SerialNumber")
                this.authService.Getemergency(serial,pincode)
                      .subscribe((data :any)=>{

                });
              }
              else if ( message.content.includes("[video]:"))
              this.userMessage = new Message(null, sender, message.content.replace("[video]:","") ,null, null,null,null,null,dateString);

              else if ( message.content.includes("[url]:"))
              this.userMessage = new Message(null, sender, null ,null, message.content.replace("[url]:",""),null,null,null,dateString);
              else if  ( message.content.includes("[position]:"))
              {
                this.userMessage = new Message(null,sender, null, null,null,this.messagedata.text.replace("[position]:",""), null , null,dateString);
                //this._listners$.next( this.userMessage.location);
              }
              else if  ( message.content.includes("[link]:"))
              this.userMessage= new Message(null, sender, null ,null, message.content.replace("[link]:","") ,null,null,null,dateString);

              else
              this.userMessage= new Message(this.messagedata.text,sender, null, null, null,null, null , null,dateString);
              this.update(this.userMessage);


             }
             else if ( message.sender_full_name===('Finder') && !this.chaturl)
             {

              this.botnum=0;
              sender= ESendBy.user  ;
              if ( message.content.includes("[photo]:"))
              this.userMessage = new Message(null,sender, message.content.replace("[photo]:",""), null, null,null, null , null,dateString);
              else if ( message.content.includes("[pincode]:"))
              {
                var pincode = message.content.replace("[pincode]:","")
                let serial = localStorage.getItem("SerialNumber")
                this.authService.Getemergency(serial,pincode)
                      .subscribe((data :any)=>{

                });
              }
              else if ( message.content.includes("[newdiscussion]:"))
              {
                var pincode = message.content.replace("[pincode]:","")
                let serial = localStorage.getItem("SerialNumber")
                this.authService.Getemergency(serial,pincode)
                      .subscribe((data :any)=>{

                });
              }
              else if ( message.content.includes("[video]:"))
              this.userMessage = new Message(null, sender, message.content.replace("[video]:","") ,null, null,null,null,null,dateString);
              else if ( message.content.includes("[url]:"))
              this.userMessage = new Message(null, sender, null ,null, message.content.replace("[url]:",""),null,null,null,dateString);
              else if  ( message.content.includes("[position]:"))
              {
                this.userMessage = new Message(null,sender, null, null,null,this.messagedata.text.replace("[position]:",""), null , null,dateString);
                //this._listners$.next( this.userMessage.location);
              }
              else if  ( message.content.includes("[link]:"))
              this.userMessage= new Message(null, sender, null ,null, message.content.replace("[link]:","") ,null,null,null,dateString);

              else
              this.userMessage= new Message(this.messagedata.text,sender, null, null, null,null, null , null,dateString);
              this.update(this.userMessage);


             }
             else
             {

              this.botnum=0;
              sender= message.sender_full_name;

              if ( message.content.includes("[photo]:"))
              this.userMessage = new Message(null,sender, message.content.replace("[photo]:",""), null, null,null, null , null,dateString);
              else if ( message.content.includes("[url]:"))
              this.userMessage = new Message(null, sender, null ,null, message.content.replace("[url]:",""),null,null,null,dateString);
              else if  ( message.content.includes("[position]:"))
              {
                this.userMessage = new Message(null,sender, null, null,null,this.messagedata.text.replace("[position]:",""), null , null,dateString);
                //this._listners$.next( this.userMessage.location);
              }
              else if ( message.content.includes("[video]:"))
              this.userMessage = new Message(null, sender, message.content.replace("[video]:","") ,null, null,null,null,null,dateString);
              else if  ( message.content.includes("[link]:"))
              this.userMessage= new Message(null, sender, null ,null, message.content.replace("[link]:","") ,null,null,null,dateString);
              else
              this.userMessage = new Message(this.messagedata.text,sender, null, null, null,null, null ,  null,dateString );
              this.update(this.userMessage);
             }

           }
           i++;
        }




       }
        catch (err){}
      },
      error=> {

      }
    );

  }


  listenSpecif(): Observable<any> {
    return this._listnersmap$.asObservable();
  }

  Sendmessage(content:string){
    const currentUser = this.authService?.currentUserValue;
    this.list = new Array(0);
    if ( currentUser!= null && currentUser?.finder_info != null)
    {
      for (let j = 0; j < currentUser.chat_members?.length; j++)
      var listmessage =currentUser.chat_members?.join(",");
      this.headers = new HttpHeaders({'idSession' :'test'});
      this.options = { headers: this.headers };
        return this.http.request('get',`${environment.apiUrl}`+this.urlsendmessage+"content="+content+"&to="+currentUser.tag_info.tag_info.serial_number
        +"&big_picture="+currentUser.tag_info.tag_info.picture_url+"&id_user="+currentUser?.finder_info.id+'&user_type=finder',this.options,);
      }
  return null;
  }
  SendmessageEmergency(content:string){
    const currentUser = this.authService?.currentUserValue;

      this.list = new Array(0);
      var listmessage =localStorage.getItem("to");
      var emergency_contact: any = JSON.parse(localStorage.getItem("emergency_contact"));

      this.headers = new HttpHeaders({'idSession' :'test'});
      this.options = { headers: this.headers };
      return this.http.request('get',`${environment.apiUrl}`+this.urlsendmessage+"content="+content+"&to="+emergency_contact.sn
      +"&id_user="+emergency_contact.id_user+'&user_type='+emergency_contact.type_user,this.options,);


  }

  Sendmessagebot(content:any , to_send_notification : number): Observable<any>{
    const currentUser = this.authService.currentUserValue;
    this.list = new Array(0);
    if ( currentUser!= null && content)
    {
    for (let j = 0; j < currentUser.chat_members?.length; j++)
    this.headers = new HttpHeaders({'idSession' :'test'});
    this.options = { headers: this.headers };
    let assistant_id = localStorage.getItem("assistant_id");

      return this.http.request('get',`${environment.apiUrl}`+this.urlsendmessage+"content="+content+"&to="+currentUser.tag_info.tag_info.serial_number+"&type=private"
      +"&big_picture="+currentUser.tag_info.tag_info.picture_url+"&id_user="+assistant_id +"&to_send_notification="+to_send_notification+"&user_type=chatbot",this.options);
    }
  return null;
  }


  upload_file(formData:FormData)
  {
      this.headers = new HttpHeaders({ 'idsession' : 'test'});
      this.options = { headers: this.headers };
      const currentUser = this.authService.currentUserValue;
      return this.http.post(`${environment.apiUrl}`+'/upload_document_pat?id_user='+currentUser?.tag_creator?.tag_creator_id,formData,this.options ).pipe(map(val => {
       return val;
      }));
  }

  GetAllMessage(idUser: string, serialNumber: string, type_user: string){

    return this.http.request('get',`${environment.apiUrl}`+`/get_disscusion_finder_side_v2?`+"id_user="+ idUser+"&conversation_name="+ serialNumber+"&user_type="+type_user);


  }

  videocall()
  {
      this.headers = new HttpHeaders({ });
      this.options = { headers: this.headers };
      const currentUser = this.authService.currentUserValue;

      return this.http.get(`${environment.apiUrl}`+'/create_bigblue?username=finder&nbr_personnes='+(currentUser.chat_members != undefined ?currentUser.chat_members?.length : 2),this.options ).pipe(map(val => {
      return val;
      }));

  }

  redirectToMedicalInformation(value: string): void {
    this.redirectToMedicalInfo = value;
    this.redirectToMedicalInfoChange.next(value);
}

  onChangeRedirectToMedicalInformation(): Observable<string> {
      return this.redirectToMedicalInfoChange.asObservable();
  }

  launchEvent(): void {
    this.launchEventValueChange.next('test');
  }

  onChangelaunchEvent(): Observable<any> {
      return this.launchEventValueChange.asObservable();
  }

  chatUrlService(id: string): Observable<any>{
      this.headers = new HttpHeaders({ });
      this.options = { headers: this.headers };
      const currentUser = this.authService.currentUserValue;

      return this.http.get(`${environment.apiUrl}`+'/chatUrl?params='+id,this.options ).pipe(map(val => {
      return val;
      }));

  }

}
