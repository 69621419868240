export const environment = {
  production: true,
  apiUrl: 'https://api.found.me',
  //apiUrl: 'https://devapi.found.me',
  //socketUrl: 'https://cranky-hofstadter.5-189-187-222.plesk.page',
   socketUrl: 'https://chat.found.me',
   dialogflow: {
    angularBot: 'c7f5e18630d04ca5b5e7d6c518fdaf25'
  },
  translateApiKey: 'm6x1YsuCGwidi2EudavRpDp7GRDgRxi20yR2vDO-Kn5v',
  webAppUrl: "https://app.found.me"
};
