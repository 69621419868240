import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../loader/loader.service';
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private activeRequests = 0;
  private loaderTimeout: any;

  constructor(private loaderService: LoaderService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
     // Show loader if it's the first active request
     this.showLoaderWithDelay()

    this.activeRequests++;

    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests--;
        // Hide loader only if there are no more active requests
        if (this.activeRequests === 0) {
          clearTimeout(this.loaderTimeout);
          this.loaderService.hide();
        }
      })
    );
  }

  private showLoaderWithDelay(): void {
    this.loaderTimeout = setTimeout(() => {
      this.loaderService.show();
    }, 300); // Show the loader with a slight delay to avoid flickering for fast requests
  }
}
