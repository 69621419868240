import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, retry, retryWhen } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService,
        private router: Router) { }

      intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retryWhen(errors => errors.pipe(
                // Retry on 500 status code
                mergeMap((error, index) => {
                    if (error.status >= 500 && index < 1) { // Retry up to 3 times
                        return of(error);
                    }
                    return throwError(error);
                })
            )),
            catchError((err: HttpErrorResponse) => {
                if ([401, 403].indexOf(err.status) !== -1) {
                    // Auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    // this.authService.logout();
                    // location.reload(true);
                }

                const error = err.error.message || err.statusText;
                return throwError(error);
            })
        );
    }
}
