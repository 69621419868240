<mat-card (countChanged)="childFunc($event)" [ngClass]="{'heightchat' : !chatview, 'heightchathidden': chatview}">
    <ng-container *ngIf="loading">
        <div class="loader-position">
            <div class="col-3">
                <div class="snippet" data-title=".dot-pulse">
                    <div class="stage">
                        <div class="dot-pulse"></div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>


    <ng-container [ngTemplateOutlet]="template">
        <div class="windowheight" [ngClass]="{'msgArea' : !chatview,  'custom-window-height': collapseIfScrolled, 'custom-chat-view-height': collapseIfScrolled && !isObject, 'chat-view-height': !collapseIfScrolled && !isObject}" #msgArea id="scrollbar-window"
            #scrollframe (scroll)="onScroll($event)">
            <!-- #scrollMe  -->
            <!-- -->
            <ng-container *ngFor="let msg of allMessages | async; let index = index;" [ngTemplateOutlet]="defaultMsgTemplate" [ngTemplateOutletContext]="{  sendBy: msg.sendBy,
                                       text: msg.content,
                                       image: msg.source,
                                       data: msg.data,
                                       option: msg.option,
                                       url: msg.url,
                                       location: msg.location,
                                       video: msg.video,
                                       num: msg.num,
                                       time: msg.time,
                                       index: index,
                                       nbMessages: nbMessages,
                                       typeUser: msg.typeUser
                                      }">


            </ng-container>

        </div>
        <mat-card-footer class="input-area align-items-center">
            <ng-container class="input-area" [ngTemplateOutlet]="inputTemplate">
            </ng-container>
        </mat-card-footer>
    </ng-container>
    <div class="text-left">
        <!--  looooool
        <div *ngIf="isTyping | async">
            <div class="snippet" data-title=".dot-pulse">
                <div class="stage">
                    <div class="dot-pulse"></div>
                </div>
            </div>
        </div> -->
    </div>

    <ng-template #defaultMsgTemplate let-text="text" let-object="object" let-url="url" let-location="location" let-data="data" let-option="option" let-image="image" let-sendBy="sendBy" let-video="video" let-num="num" let-time="time" let-index="index" let-nbMessages="nbMessages"
        let-typeUser="typeUser">
        <chat-msg [msg]="{ sendBy: sendBy,text: text , image :image , data:data, option:option , url:url , location:location , video :video , num :num , time:time, index:index, nbMessages:nbMessages, typeUser: typeUser} " [sender]="sender "></chat-msg>
    </ng-template>

    <ng-template #defaultInputTemplate>
        <chat-input (keydown.enter)="onChange($event.target); " (change)="onChangefile($event.target); " (keyup.enter)="onChange($event.target); " (keyup)="onKey($event) " (newItemEvent)="addItem($event) "></chat-input>
    </ng-template>

</mat-card>
