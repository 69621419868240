import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { SignupService } from '../services/signup/signup.service';
import { OptionsPet } from 'src/app/Models/PetTypes';
import { PetInformations } from 'src/app/Models/Pet';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbdPopupmodelDocContent } from '../listmodal/popupmodeldoc/popupmodeldoc.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-pet-form',
  templateUrl: './pet-landingpage.component.html',
  styleUrls: ['./pet-landingpage.component.scss'],
})
export class PetLandingpageComponent implements OnInit {
  @Output() emitClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  addPetForms: FormGroup;
  formulaire: FormGroup;
  id_groupe: string;
  id_language: string = localStorage.getItem('languageId');
  id_user: string;
  petInfo: PetInformations = new PetInformations();

  optionsPet: OptionsPet = new OptionsPet();
  selectedOption: any;
  showPassword = false;
  showPasswordConfirm = false;
  password: string;
  confirmpassword: string;
  passwordError: boolean = false;
  typeListe: any;
  serialNumber: string;
  id_member: any;
  tag: any;
  isLoading:boolean=false;
  constructor(private signUpService: SignupService,private modalService:NgbModal,
  private safeUrl: DomSanitizer) {}

  ngOnInit(): void {

    this.formulaire = new FormGroup({
      firstname: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z]+$")]),
      lastname: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z]+$")]),
      email: new FormControl('', [Validators.required,  Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      code_phone: new FormControl(''),
      mobile: new FormControl('',[Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$")]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(
          '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
        ),
      ]),
      confirmpassword:new FormControl('',Validators.required),
    });
    this.addPetForms = new FormGroup({
      petCategorie: new FormControl('', Validators.required),
      namePet: new FormControl('', Validators.required),
    });

    this.signUpService.getAllTypeOfPet().subscribe(
      (data) => {
        this.optionsPet = data;

      },
      (error) => {
        console.log(error);
      }
    );
  }

  getSafeUrl(url: string): SafeUrl{
    return this.safeUrl.bypassSecurityTrustResourceUrl(url);
  }

  open(content,type,text,color) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
     windowClass : "myCustomModalClass",

    };

    const modeldoc =this.modalService.open(NgbdPopupmodelDocContent,options);
    modeldoc.componentInstance.chatinfo = true;
    modeldoc.componentInstance.number = 1;
    modeldoc.componentInstance.title =content;
    modeldoc.componentInstance.texte=text;
    modeldoc.componentInstance.color=color;
    modeldoc.componentInstance.type=type;
    modeldoc.dismissed.subscribe((result)=>{
      modeldoc.close();
      this.emitClose.emit(true);
    })
    modeldoc.componentInstance.event.subscribe((data:any) => {
      modeldoc.close();
      this.emitClose.emit(true);
    });
  }

  arePasswordsEqual() {
    return this.password === this.confirmpassword;
  }
  onsubmit() {
    this.isLoading=true;
    if (this.formulaire.valid) {
      this.signUpService.signUp(this.formulaire)
      .subscribe((res) => {
        this.id_groupe = res.id_group;
        this.id_member = res.id_member_md5;
        this.serialNumber=localStorage.getItem("SerialNumber");
        // this.serialNumber = 'PRNJEHM';
        this.id_user = res.id_user;

        this.petInfo.general_info.name=this.addPetForms.get("namePet").value;
        this.signUpService
          .getScanTag(this.serialNumber,this.id_user,this.id_member,this.id_language,this.id_groupe)
          .subscribe((res) => {
            this.tag = res.new_tag[0];
            this.petInfo.currency=this.tag.currency;
            this.petInfo.emergency_contact_user=this.tag.emergency_contact_user;
            this.petInfo.other_info=this.tag.other_info;
            this.petInfo.tag_info=this.tag.tag_info;
            this.petInfo.tag_user_info=this.tag.tag_user_info;
            this.petInfo.preference_user=this.tag.preference_user;
            this.petInfo.general_info.pet_picture_url=this.addPetForms.get("petCategorie").value.icon
            this.petInfo.member_info.first_name=this.formulaire.get("firstname").value;
            this.petInfo.member_info.last_name=this.formulaire.get("lastname").value;
            this.petInfo.member_info.mail=this.formulaire.get("email").value;
            this.petInfo.member_info.mobile=this.formulaire.get("mobile").value;
            this.petInfo.member_info.phone_code_string=this.formulaire.get("code_phone").value;
            this.petInfo.general_info.id_pet=this.addPetForms.get("petCategorie").value.id

            if (this.addPetForms.valid) {
              this.signUpService.addPet(this.id_groupe,this.id_user,this.id_language,this.petInfo)
                .subscribe((res) => {
                  this.addPetForms.reset();
                  this.formulaire.reset();
                  this.petInfo = new PetInformations();
                  this.open("succeslandingpage_pet_v1","success","success_registration_title","#04a6bc");
                  localStorage.removeItem('landing_page')
                  this.isLoading=false;
                },(error)=>{
                  this.open("erreurgeneralelandingpage","error","error_popup_text1","#f4c809");
                  this.isLoading=false;
                });
            }

          },(error)=>{
            this.open("serial_number_already_activated_text_app","error","sn_err1_title","#f4c809");
            this.isLoading = false;
          });

      },(error)=>{
        this.open("compte_exist","error","login_problem_active_title_app","#f4c809");
        this.isLoading = false;
      });
    }
  }

  get getPassword() {
    return this.formulaire.get('password');
  }
}
