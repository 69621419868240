import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/services/shared-service/shared.service';
import { CustomValidators } from './CustomValidators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { NgbdModalResponse } from '../listmodal/modelResponse/model.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {


  resetForm: FormGroup;
  idUser: string = '';
  isSubmited: boolean = false;
  loading: boolean = false;
  hideNewPassword: boolean = true;
  hideConfirmPassword: boolean = true;
  successMessage: string = '';
  constructor(private _formBuilder: FormBuilder, private _sharedService: SharedService, private _activatedRoute: ActivatedRoute, private modalService: NgbModal) { }

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(params => {
      this.idUser = params.id_user
    })
    this.resetForm = this._formBuilder.group({
      newPassword:['',
      [
        Validators.required,
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          // check whether the entered password has a lower case letter
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          // check whether the entered password has a special character
          CustomValidators.patternValidator(/[!@#$%^&*(),.?":{}|<>]/,{
              hasSpecialCharacters: true
          }),
          CustomValidators.patternValidator(/[^\s]+/,{
            hasSpace: true
          }),
          Validators.minLength(8)
        ])
       ]
    ],
      validationPassword: [
        '',
        [
          Validators.required,
         ]
      ]
    },{
      // check whether our password and confirm password match
      validator: CustomValidators.passwordMatchValidator
   })
  }

  onResetPassword(): void {
    if(this.resetForm.invalid){
      this.openAlert('error_v2', "Oops, incorrect password !", "#DC3545","The password you entered is incorrect. Please try again");
    }else{
      this.loading = true;
      this._sharedService.resetPassword(this.idUser, this.resetForm.value.newPassword, this.resetForm.value.validationPassword)
      .subscribe((response)=>{
        this.openAlert('success_v2', 'Success',"#28a79a");
        this.loading = false;
      }, (error)=>{
        this.loading = false;
        this.openAlert('error_v2', error?.error?.data?.title, "#F4C809", error?.error?.data?.text);
      })
    }
  }

  openAlert(type :string,title: string,color: string, text?: string) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
      windowClass : "myDocModalClass"

    };
    const modeldoc =this.modalService.open(NgbdModalResponse,options);
    modeldoc.componentInstance.text = text
    modeldoc.componentInstance.title = title
    modeldoc.componentInstance.type = type
    modeldoc.componentInstance.color = color
    modeldoc.dismissed.subscribe((result)=>{
      modeldoc.close();
      if(type == 'success_v2'){
        window.self.close();
        window.open(environment.webAppUrl, '_self')
      }

    });
  }

}
